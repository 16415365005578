import styled, { css } from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Icon } from '../../icon/icon'
import { rem } from '../../../utils'

type StyledListItemProps = {
  $header?: boolean
  $isTitle?: boolean
  $utility?: boolean
  $back?: boolean
  $secondary?: boolean
}

const getListItemBackgroundColor = (props: StyledListItemProps) => {
  if (props.$isTitle)
    return 'white'
  if (props.$header)
    return '#f8f8f8'
  if (props.$back)
    return '#fcf8ef'

  return 'white'
}

export const StyledListItem = styled.li<StyledListItemProps>`
  height: ${props => rem(66, props.theme)};
  width: auto;
  max-width: unset;
  border-bottom: ${props => props.$utility || props.$back ? '0': '1px solid rgba(0, 0, 0, 0.25)'};
  background-color: ${getListItemBackgroundColor};
  cursor: ${props => !props.$header && 'pointer'};

  ${props => props.$utility && css`
    height: ${props => rem(52, props.theme)};
  `}

  ${props => props.$isTitle && css`
    height: ${props => rem(102, props.theme)};
  `}
`

export const StyledListItemGrid = styled(Grid)`
  height: 100%;
  padding: 0 ${props => props.theme.typography.pxToRem(22)} 0 ${props => props.theme.typography.pxToRem(40)};
`

export const StyledHeaderText = styled.p<{ $secondary?: boolean }>`
  color: ${props => props.$secondary ? props.theme.palette.text.secondary : props.theme.palette.secondary.dark};
  margin: 0 ${props => props.$secondary ? 0 : props.theme.typography.pxToRem(4)} 0 0;
  font-weight: 500;
  line-height: 1;
`

export const StyledCallButton = styled(Button)`
  border: 2px solid ${props => props.theme.palette.text.secondary};
  font-size: ${props => props.theme.typography.pxToRem(15)};
  margin-right: ${props => props.theme.typography.pxToRem(4)};
  padding: ${props => rem(13, props.theme)} ${props => rem(6, props.theme)} ${props => rem(11, props.theme)};

  ${props => props.theme.breakpoints.up(360)} {
    margin-right: ${props => props.theme.typography.pxToRem(20)};
    padding: ${props => rem(13, props.theme)} ${props => rem(13, props.theme)} ${props => rem(11, props.theme)};
  }
`

export const StyledTextSpan = styled.div`
  display: flex;
  font-size: ${props => props.theme.typography.pxToRem(16)};
  align-items: center;
  column-gap: ${props => props.theme.typography.pxToRem(5)};
  ${props => props.theme.breakpoints.up(360)} {
    column-gap: ${props => props.theme.typography.pxToRem(20)};
  }

  > a {
    text-decoration: none;

    > p {
      margin-top: 0;
    }
  }
`

export const StyledButton = styled(Button)`
  min-width: unset;
  width: ${props => props.theme.typography.pxToRem(32)};
  ${props => props.theme.breakpoints.up(360)} {
    width: unset;
  }
`

export const StyledCloseIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(34)};
`

export const StyledMenuItemText = styled.p`
  margin: 0;
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(18)};
  font-weight: 500;
`

export const StyledLinkItemText = styled.p<{$isTitle?:boolean; $isUtility?:boolean}>`
  display: flex;
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.$isTitle ? props.theme.typography.pxToRem(22) : props.theme.typography.pxToRem(18)};
  margin-bottom: 0;
  transition: color 0.2s ease;
  text-decoration: none;
  font-weight: ${$props => !$props.$isUtility && '500'};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(19)};
  }

  &:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }
`
