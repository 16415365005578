import styled, { css } from 'styled-components'
import { StyledNavItem } from './NavItem'
import AppBar from '@material-ui/core/AppBar'
import { StyledDropdownPanel } from '../NavigationDropdown/NavDropdownPanel'
import { rem } from '../../utils'

export const StyledLogoWrapper = styled.div`
  ${props => props.theme.breakpoints.up('lg')} {
    position: absolute;
    transition: top 0.2s ease-in-out;
    left: ${props => props.theme.typography.pxToRem(65)};
  }
`

type NavProps = { $secondary?: boolean; $sticky?: boolean; $visibleHeader?: boolean; $isOpen?: boolean }

const headerHeight = css<NavProps>`
  height: ${props => props.theme.typography.pxToRem(74)};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.$secondary ? props => props.theme.typography.pxToRem(104) : props.theme.typography.pxToRem(128)};

    ${props => props.$sticky && css`
      height: ${props => props.theme.typography.pxToRem(74)};
    `}
  }
`

export const StyledNavBuffer = styled.div<NavProps>`
  height: ${props => props.theme.typography.pxToRem(74)};

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.$secondary ? props.theme.typography.pxToRem(104) : props.theme.typography.pxToRem(128)};
  }
`

export const StyledNavigationContainer = styled(AppBar)<NavProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: ${props => props.theme.palette.primary.dark};
  box-shadow: none;
  background-color: ${props => props.$secondary || props.$sticky || (props.$isOpen && !props.$sticky) ? 'white' : 'transparent'};
  width: 100%;
  padding: ${props => props.theme.typography.pxToRem(20)} ${props => props.theme.typography.pxToRem(24)} 0;
  border-bottom: ${props => props.$sticky ? props.theme.typography.pxToRem(1) : '0'} solid rgba(0, 0, 0, 0.1);
  transition-property: height, transform, background-color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-in-out !important;
  ${headerHeight}
  ${props => props.theme.breakpoints.up('lg')} {
    align-items: center;
    padding: 0;

    ${StyledLogoWrapper} {
      top: ${props => props.theme.typography.pxToRem(props.$sticky ? 12 : (props.$secondary ? 20 : 32))};
    }
  }

  ${StyledNavItem} {
    &::after {
      transform: ${props => props.$secondary ? 'translateY(10px)' : 'translateY(20px)'};
    }

    padding-top: ${props => (props.$visibleHeader && (props.$secondary ? props.theme.typography.pxToRem(32) : props.theme.typography.pxToRem(props.$sticky? 10: 16))) || ( !props.$visibleHeader &&  props.theme.typography.pxToRem(props.$sticky? 10: 16)  )};
    padding-bottom: ${props => props.$visibleHeader && (props.$secondary ? props.theme.typography.pxToRem(32) : props.theme.typography.pxToRem(40))};

    ${props => props.theme.breakpoints.up('lg')} {
      padding-top: ${props => props.$visibleHeader && (props.$secondary ? props.theme.typography.pxToRem(32) : props.theme.typography.pxToRem(40))};
      padding-bottom: ${props => props.$visibleHeader && (props.$secondary ? props.theme.typography.pxToRem(32) : props.theme.typography.pxToRem(40))};
    }
  }

  ${StyledDropdownPanel} {
    top: ${props => (props.$secondary || props.$sticky) ? props => props.theme.typography.pxToRem(68) : props.theme.typography.pxToRem(120)};
  }
`

export const StyledRightMenuContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  order: 2;
  flex: 0 0 auto;
  column-gap: ${props => rem(6, props.theme)};

  ${props => props.theme.breakpoints.up(360)} {
    column-gap: ${props => rem(20, props.theme)};
  }

  ${props => props.theme.breakpoints.up('lg')} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${props => props.theme.typography.pxToRem(65)};
    grid-gap: ${props => props.theme.typography.pxToRem(28)};
  }
`
