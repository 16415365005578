import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Link, LinkProps } from '../../link/link'
import { StyledLinkItemText, StyledListItem, StyledListItemGrid, StyledTextSpan } from './styledMobileMenuItem'
import Grid from '@material-ui/core/Grid'

export type MobileMenuUtilityItemProps = React.ComponentProps<typeof StyledListItem> & Pick<LinkProps, 'href'> & {
  icon: ReactNode
  children: string
}

export const StyledIconContainer = styled.div`
  width: ${props => props.theme.typography.pxToRem(21)};
  margin-right: ${props => props.theme.typography.pxToRem(20)};
`

export const MobileMenuUtilityItem: React.FC<MobileMenuUtilityItemProps> = ({ onClose, href, icon, children, ...props }) => {
  return (
    <StyledListItem $utility={true} {...props}>
      <StyledListItemGrid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <StyledTextSpan>
            <StyledIconContainer>
              { icon }
            </StyledIconContainer>
            <Link href={href} passHref>
              <StyledLinkItemText $isUtility>
                { children }
              </StyledLinkItemText>
            </Link>
          </StyledTextSpan>
        </Grid>
      </StyledListItemGrid>
    </StyledListItem>
  )
}
