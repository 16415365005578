import React, { MouseEvent, useCallback } from 'react'
import { useAutocomplete, useQuery } from '@sajari/react-hooks'
import styled, { useTheme } from 'styled-components'
import { useMediaQuery } from '@material-ui/core'
import { Link } from '../link/link'
import { irem } from '../../utils/rem'
import { useRouter } from 'next/router'

export type AutocompleteResultsProps = {
  setInput: (string) => void
}

const StyledSuggestionList = styled.ul`
  list-style: none;
  position: absolute;
  top: calc(100% - ${irem(16)});
  background: white;
  left: ${props => props.theme.typography.pxToRem(16)};
  right: ${props => props.theme.typography.pxToRem(16)};
  border-top: 2px solid ${props => props.theme.palette.primary.main};
  padding: ${props => props.theme.spacing(1)} 0;
  margin: 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  ${props => props.theme.breakpoints.up('md')} {
    left: 0;
    right: ${irem(10)};
    top: calc(100% - 2px);
  }
`

const StyledSuggestionListItem = styled.li`
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;

  > a {
    display: block;
    padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(3)};
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      background: #f2f2f2;
    }
  }

  &::before {
    display: none;
  }
`

export const AutocompleteResults: React.VFC<AutocompleteResultsProps> = ({ setInput }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { suggestions } = useAutocomplete()
  const router = useRouter()
  const { setQuery } = useQuery()

  const onClick = useCallback((e: MouseEvent<HTMLAnchorElement>, suggestion: string) => {
    setInput(suggestion)

    if (router?.route === '/search') {
      e.preventDefault()
      setQuery(suggestion)
    }
  }, [router, setInput, setQuery])

  return suggestions.length > 0 ? (
    <StyledSuggestionList>
      {suggestions.slice(0, isMobile ? 3 : 6).map((suggestion, i) => (
        <StyledSuggestionListItem key={i}>
          <Link href={`/search?q=${suggestion}`} passHref>
            {/* eslint-disable-next-line */}
            <a onClick={(e) => onClick(e, suggestion)}>
              {suggestion}
            </a>
          </Link>
        </StyledSuggestionListItem>
      ))}
    </StyledSuggestionList>
  ) : null
}