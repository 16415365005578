import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'

export const StyledDisclaimerWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.typography.pxToRem(14)};
  ${props => props.theme.breakpoints.up('sm')} {
    gap: ${props => props.theme.typography.pxToRem(19)};
  }
`

export const StyleDisclaimerTitleContainer = styled.div`
  padding-left: ${props => props.theme.typography.pxToRem(18)};
  ${props => props.theme.breakpoints.up('sm')} {
    padding-left: ${props => props.theme.typography.pxToRem(33)};
  }
`

export const StyledDisclaimerTitle = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  font-weight: 500;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(17)};
  }
`

export const StyledDisclaimerTextBlockContainer = styled.div`
  background-color: ${alpha('#f2f2f2', 0.5)};
  padding: ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(17)} ${props => props.theme.typography.pxToRem(7)} ${props => props.theme.typography.pxToRem(18)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(14)} ${props => props.theme.typography.pxToRem(33)};
  }
`

export const StyledDisclaimerTextBlock = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(12)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(14)};

    > br {
      line-height: 150%;
    }
  }

  > * {
    margin: 0;
    font-size: ${props => props.theme.typography.pxToRem(12)};

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(14)};
    }
  }
`